* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html,
body,
.piano-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
html .heng-reminder,
body .heng-reminder,
.piano-container .heng-reminder {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  z-index: 5;
}
html .heng-reminder img,
body .heng-reminder img,
.piano-container .heng-reminder img {
  width: 50vw;
  animation: rotate 2s infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(90deg);
  }
}
html .key-area,
body .key-area,
.piano-container .key-area {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
html .key-area .white-keys,
body .key-area .white-keys,
.piano-container .key-area .white-keys {
  position: absolute;
  white-space: nowrap;
  width: auto;
  height: 100vh;
  left: 0;
  top: 0;
}
html .key-area .white-keys .item,
body .key-area .white-keys .item,
.piano-container .key-area .white-keys .item {
  width: 80px;
  height: 100%;
  display: inline-block;
  border-left: solid 1px #333;
  border-top: solid 1px #333;
  border-bottom: solid 1px #333;
}
html .key-area .white-keys .item p,
body .key-area .white-keys .item p,
.piano-container .key-area .white-keys .item p {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
}
html .key-area .white-keys .item .active,
body .key-area .white-keys .item .active,
.piano-container .key-area .white-keys .item .active {
  background: #ddd;
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.6);
}
html .key-area .white-keys .item:last-child,
body .key-area .white-keys .item:last-child,
.piano-container .key-area .white-keys .item:last-child {
  border-right: solid 1px #333;
}
html .key-area .black-keys,
body .key-area .black-keys,
.piano-container .key-area .black-keys {
  position: absolute;
  width: 100%;
  height: 20px;
}
html .key-area .black-keys .item,
body .key-area .black-keys .item,
.piano-container .key-area .black-keys .item {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 50vh;
  background: #000;
  border-radius: 0 0 8px 8px;
}
html .key-area .black-keys .active,
body .key-area .black-keys .active,
.piano-container .key-area .black-keys .active {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}
html .mini-area,
body .mini-area,
.piano-container .mini-area {
  width: 50vw;
  height: 20vh;
  border: solid 1px #ddd;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  z-index: 2;
  background: #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
html .mini-area > .mask,
body .mini-area > .mask,
.piano-container .mini-area > .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
html .mini-area > .highlight,
body .mini-area > .highlight,
.piano-container .mini-area > .highlight {
  height: 100%;
  position: absolute;
  width: 50px;
  left: -1px;
  top: -1px;
  z-index: 10;
  box-shadow: 0px 0px 10px #272525;
  overflow: hidden;
}
html .mini-area > .highlight > .mini-area,
body .mini-area > .highlight > .mini-area,
.piano-container .mini-area > .highlight > .mini-area {
  left: 0;
  top: 0;
  transform: translateX(0);
  z-index: 0;
}
html .mini-area > .white-keys,
body .mini-area > .white-keys,
.piano-container .mini-area > .white-keys {
  width: 100%;
  height: 100%;
  display: flex;
}
html .mini-area > .white-keys .item,
body .mini-area > .white-keys .item,
.piano-container .mini-area > .white-keys .item {
  flex: 1;
  background: white;
  border-left: solid 1px #333;
  border-top: solid 1px #333;
  border-bottom: solid 1px #333;
  position: relative;
}
html .mini-area > .white-keys .item:last-child,
body .mini-area > .white-keys .item:last-child,
.piano-container .mini-area > .white-keys .item:last-child {
  border-right: solid 1px #333;
}
html .mini-area > .white-keys .item p,
body .mini-area > .white-keys .item p,
.piano-container .mini-area > .white-keys .item p {
  position: absolute;
  font-size: 12px;
  width: 100%;
  transform: scale(0.4);
  bottom: 0px;
}
html .mini-area > .black-keys,
body .mini-area > .black-keys,
.piano-container .mini-area > .black-keys {
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
}
html .mini-area > .black-keys .item,
body .mini-area > .black-keys .item,
.piano-container .mini-area > .black-keys .item {
  height: 10vh;
  position: absolute;
  background: black;
  top: 0;
}
